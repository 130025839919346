import { preloadImages } from '../../utils/preLoadImage';
import { Preview } from './preview';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { Flip } from 'gsap/Flip';
gsap.registerPlugin(Flip);

const previewElems = [...document.querySelectorAll('.preview')];
const contentElems = [...document.querySelectorAll('.contentv6')];
const previewItems = [];
previewElems.forEach((item, pos) => {
	previewItems.push(new Preview(item, contentElems[pos]))
});

const animateOnScroll = () => {

	for (const item of previewItems) {

		gsap.set(item.DOM.imageInner, {transformOrigin: '50% 0%'});
		
		item.scrollTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: item.DOM.el,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true
			}
		})
		.addLabel('start', 0)
		.to(item.DOM.title, {
			ease: 'none',
			yPercent: -100
		}, 'start')
		.to(item.DOM.imageInner, {
			ease: 'none',
			scaleY: 1.8,
		}, 'start')

	}

};

// Preload images and initialize scrolling animations
preloadImages('.preview__img-inner, .contentv6__thumbs-item').then( _ => {
	document.body.classList.remove('loading');
});

export default animateOnScroll;
